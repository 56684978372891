import axios from 'axios'
import toraPortalApi from './ToraPortalApi'
import qs from 'qs'
import _ from 'lodash'
import utils from '../utils'
import toraPortalConfig from '../config'

class ToraPortalApiService {

  verifyResetPasswordLinkKey (key) {
    return toraPortalApi.get(`/account/reset-password/verify/${key}`)
                        .then((response) => {
        return response
      })
                        .catch((err) => {
        throw err
      })
  }

  loginUser (username, password, rememberMe) {
    return toraPortalApi.post('/authenticate',
                              {
        username,
        rememberMe,
        password
      })
                        .then((response) => {
        localStorage.setItem('token', response.id_token)
        return this.getUserDetails()
      })
                        .then((user) => {
        localStorage.setItem('user', JSON.stringify(user))
        return true
      })
  }

  recaptchaCheck (token) {
    return toraPortalApi.post('/authenticate/recaptcha/verify', {
      token
    })
  }

  checkUser (email, password, rememberMe) {
    return toraPortalApi.post('/authenticate/checkUser',
                              {
        email,
        rememberMe,
        password
      })
                        .then((response) => {
        return true
      })
  }

  authenticateTwoFactor (digitCode, email, password, rememberMe) {
    console.log('Trying to authenticate - Before request ... ' + new Date())
    return toraPortalApi.post('/authenticate/authenticateTwoFactor',
                              {
        digitCode,
        email,
        rememberMe,
        password
      })
                        .then((response) => {
        console.log('Success authentication - Request successful ' + new Date())
        localStorage.setItem('token', 'Bearer ' + response.id_token)
        return this.reloadUserDetails()
      })
  }

  publishSSOLoginCompleted () {
    return toraPortalApi.post('/authenticate/sso-auth-completed')
  }

  reloadUserDetails () {
    utils.logoutIfExpired();
    return this.getUserDetails()
      .then((user) => {
        localStorage.setItem('user', JSON.stringify(user))
        localStorage.setItem('kyc_status', user.kycStatus)
        return true
      })
  }

  getUserDetails () {
    utils.logoutIfExpired();
    return toraPortalApi.get('/account')
                        .then((userResponse) => {
        return userResponse
      })
  }

  getResourcesFileForClient (clientName) {
    utils.logoutIfExpired();
    return toraPortalApi.get(`/resources/all-client-files/${clientName}`)
                        .then((resourceFiles) => {
        const filesTree = {}

        resourceFiles.map((fileResource) => {
          const pathParts = fileResource.key.split('/')
          let auxTree = filesTree

          if (pathParts.length === 2) {
            // case when we have a resource directly in client root folder
            if (pathParts[0] in auxTree) {
              auxTree = auxTree[pathParts[0]]
            } else {
              // if there already are resources in root folder
              auxTree[pathParts[0]] = {}
              auxTree = auxTree[pathParts[0]]
            }
            if (!Array.isArray(auxTree.children)) {
              auxTree.children = []
            }
            auxTree.children.push(fileResource)
            auxTree.children.map((file) => {
              file.fullName = file.name
            })
          } else {
            for (let i = 0; i < pathParts.length - 1; i++) {
              if (pathParts[i] in auxTree) {
                auxTree = auxTree[pathParts[i]]
              } else {
                auxTree[pathParts[i]] = {}
                auxTree = auxTree[pathParts[i]]
              }
            }
            if (!Array.isArray(auxTree.children)) {
              auxTree.children = []
            }
            auxTree.children.push(fileResource)
            auxTree.children.map((file) => {
              file.fullName = file.name + (file.extension === '' ? '' : '.') + file.extension
            })
          }
        })
        return filesTree
      })
  };

  getResourceFiles () {
    utils.logoutIfExpired();
    return toraPortalApi.get('/resources/all-client-files')
                        .then((resourceFiles) => {
        const filesTree = {}

        resourceFiles.map((fileResource) => {
          const pathParts = fileResource.key.split('/')
          let auxTree = filesTree

          if (pathParts.length === 2) {
            // case when we have a resource directly in client root folder
            if (pathParts[0] in auxTree) {
              auxTree = auxTree[pathParts[0]]
            } else {
              // if there already are resources in root folder
              auxTree[pathParts[0]] = {}
              auxTree = auxTree[pathParts[0]]
            }
            if (!Array.isArray(auxTree.children)) {
              auxTree.children = []
            }
            auxTree.children.push(fileResource)
            auxTree.children.map((file) => {
              file.fullName = file.name
            })
          } else {
            for (let i = 0; i < pathParts.length - 1; i++) {
              if (pathParts[i] in auxTree) {
                auxTree = auxTree[pathParts[i]]
              } else {
                auxTree[pathParts[i]] = {}
                auxTree = auxTree[pathParts[i]]
              }
            }
            if (!Array.isArray(auxTree.children)) {
              auxTree.children = []
            }
            auxTree.children.push(fileResource)
            auxTree.children.map((file) => {
              file.fullName = file.name + (file.extension === '' ? '' : '.') + file.extension
            })
          }
        })
        return filesTree
      })
  }

  resetPasswordSendEmail (mail) {
    return toraPortalApi.post('/account/reset-password/sendEmail',
                              { email: mail })
                        .then((resp) => {
        return resp
      })
  }

  initResetPassword (newPassword, resetKey) {
    return toraPortalApi.post('/account/reset-password/init', { newPassword, resetKey })
                        .then((resp) => {
        return resp
      })
  }

  finishResetPassword (digitCode, key, newPassword) {
    return toraPortalApi.post('/account/reset-password/finish', { digitCode, newPassword, key })
                        .then((resp) => {
        return resp
      })
  }

  initChangePassword (email, newPassword, oldPassword) {
    return toraPortalApi.post('/account/change-password/init', { email, newPassword, oldPassword })
                        .then((resp) => {
        return resp
      })
  }

  finishChangePassword (digitCode, email, password, rememberMe) {
    return toraPortalApi.post('/account/change-password/finish', { digitCode, email, password, rememberMe })
                        .then((resp) => {
        return resp
      })
  }

  sendGetInTouchRequest (firstName, email, company = null, role = null) {
    utils.logoutIfExpired();
    const getInTouchData = {
      oid: '00D300000001BGa',
      retURL: toraPortalConfig.GET_IN_TOUCH_FORM_RETURN_URL,
      Crypto_Related_Flag__c: '1',
      description: 'New Get-In-Touch inquiry from TORA LSEG Digital Assets Portal',
      first_name: firstName,
      email: email
    }

    if (!_.isNull(company)) {
      getInTouchData.company = company
    }

    if (!_.isNull(role)) {
      getInTouchData.role = role
    }

    return axios({
      method: 'POST',
      url: toraPortalConfig.GET_IN_TOUCH_SALESFORCE_URL,
      data: qs.stringify(getInTouchData)
    })
  }

  verifyDigitCode (digitCode, email, password, rememberMe) {
    return toraPortalApi.post('/users/verifyDigitCode', { digitCode, email, password, rememberMe })
                        .then((resp) => {
        return resp
      })
  }

  getClientList () {
    utils.logoutIfExpired();
    return toraPortalApi.get('/clients/active')
                        .then((resp) => resp)
  }

  getAvailableReportDates (clientName, reportType) {
    utils.logoutIfExpired();
    return toraPortalApi.get(`/reports/${reportType}/client/${clientName}`)
                        .then((resp) => resp)
  }

  getAvailableReports (fundName, year, month, reportType) {
    utils.logoutIfExpired();
    return toraPortalApi.get(`/reports/${reportType}/fund/${fundName}/${year}/${month}`)
                        .then((resp) => resp)
  }

  getAvailableNonDailyReports (fundName, year, month, reportType, subType) {
    utils.logoutIfExpired();
    return toraPortalApi.get(`/nd-reports/${reportType}/fund/${fundName}/${subType}/${year}/${month}`)
                        .then((resp) => resp)
  }

  getLimitDefinitionReportForFund (fundName) {
    utils.logoutIfExpired();
    return toraPortalApi.get(`/reports/limit/definitionReport/${fundName}`)
                        .then((resp) => resp)
  }

  getAllFundsForClient = clientName => {
    utils.logoutIfExpired();
    return toraPortalApi.get(`/funds/client/${clientName}`)
                        .then((resp) => resp)
  }

  getAllAccountCapabilities (group, ignoreUser = false, ignoreExchange = false) {
    utils.logoutIfExpired();
    return toraPortalApi.get(`/account-capabilities/${group}/all`, {params: {ignoreUser: ignoreUser, ignoreExchange:ignoreExchange}})
                        .then((resp) => resp)
  }

  getAllBrokerCapabilities (group, ignoreUser = false, ignoreExchange = false) {
    utils.logoutIfExpired();
    return toraPortalApi.get(`/broker-capabilities/${group}/all`, {params: {ignoreUser: ignoreUser, ignoreExchange:ignoreExchange}})
                        .then((resp) => resp)
  }

  getAllMarketCapabilities (group, ignoreUser = false, ignoreExchange = false) {
    utils.logoutIfExpired();
    return toraPortalApi.get(`/market-capabilities/${group}/all`, {params: {ignoreUser: ignoreUser, ignoreExchange:ignoreExchange}})
                        .then((resp) => resp)
  }

  getAllInternalAccountAndTradebookCapabilities (group, ignoreUser = false, ignoreExchange = false) {
    utils.logoutIfExpired();
    return toraPortalApi.get(`/iatb-capabilities/${group}/all`, {params: {ignoreUser: ignoreUser, ignoreExchange:ignoreExchange}})
                        .then((resp) => resp)
  }

  downloadFile (downloadURL) {
    utils.logoutIfExpired();
    toraPortalApi.get(downloadURL).then((resp) => {
      window.open(resp, '_blank')
    })
  }

  getUserGuide() {
    utils.logoutIfExpired();
    return toraPortalApi.get('/user-guides/guide')
                        .then((resp) => resp)
  }

  postDownloadedFile(downloadFileName, downloadPath) {
    let resource = {
      nameFile: downloadFileName,
      urlPath: downloadPath,
    }
    return toraPortalApi.post('/audit/download-file-resource-audit', resource)
                        .then((resp) => resp)
  }

  postWebPageAccessed(pageName, path) {
    let resource = {
      pageName: pageName,
      path: path,
    }
    return toraPortalApi.post('/audit/user-page-accessed', resource)
                        .then((resp) => resp)
  }

    getAllClientVersions() {
      utils.logoutIfExpired();
      return toraPortalApi.get('/reports/all-client-versions')
                          .then((resp) => resp)
    }

    syncClientVersions() {
      utils.logoutIfExpired();
      return toraPortalApi.post('/reports/sync-client-versions')
                          .then((resp) => resp)
    }

    getLastSync(syncName) {
      utils.logoutIfExpired();
      return toraPortalApi.get(`/sync/last-sync-time/${syncName}`)
                          .then((resp) => resp)
    }
}

export default new ToraPortalApiService()
